.sn_newsletter {
	padding: 65px 0;
	background: $body-color;

	&_title {
		@extend .h4;
		color: #fff;
		margin-bottom: 20px;
		font-family: $franklin;

		@include mq(md){
			margin-bottom: 20px;
		}
	}

	&_form {

		form {
			flex-grow: 1;
			align-items: flex-start;

			.form-group {
				display: block;
				flex-grow: 1;
				width: 100%;
				order: 1;
				margin-bottom: 15px;

				@include mq(lg){
					width: auto;
					flex-basis: 0;
					margin-bottom: 0;
					margin-right: 20px;
				}

				.form-control {
					border-radius: 0;
					width: 100%;
					font-size: 16px;
					height: 40px;
					padding: 2px 15px;

					@include placeholder {
						font-style: italic;
					}
				}
			}

			.custom-control {
				margin-bottom: 15px;
				order: 3;
				color: #fff;
				width: 100%;

				@include mq(lg){
					order: 4;
					margin-top: 10px;
					margin-bottom: 0;
				}
			}

			.btn {
				order: 4;
				padding-top: 0;
				padding-bottom: 0;
				min-height: 40px;

				@include mq(lg){
					order: 3;
				}
			}
		}

	}
}
