.sn_block_background_list {
	position: relative;
	padding: 50px 0;
	overflow: hidden;

	@include mq(md){
		padding: 75px 0;
	}

	&_bg {
		position: absolute;
		top: -7px; left: -7px; right: -7px; bottom: -7px;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		filter: blur(7px);

		&::before {
			content: '';
		  position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			background: rgba(#3e3c37, .35);
		}
	}

	&_ct {
		position: relative;
		z-index: 1;
		opacity: 0;
		filter: blur(15px);
		transition: $transition-smooth;

		&._in {
			opacity: 1;
			filter: blur(0);
		}


		ul {
			padding: 0;
			list-style: none;
			margin: 0;
			column-gap: 40px;

			@include mq(md){
				column-count: 2;
			}

			@include mq(lg){
				column-count: 3;
			}

			li {
				padding-left: 20px;
				position: relative;
				line-height: 1.3;
				margin-bottom: 16px;

				@include mq(md){
					display: inline-block;
					width: 100%;
				}

				&::before {
					content: '•';
					position: absolute;
					top: 0;
					left: 0;
				}
			}
		}
	}

	&, * {
		color: #fff;
	}
}
