.btn {
	font-family: $franklin;
	text-transform: uppercase;
	box-shadow: none !important;
	font-size: 17px;
	padding: 13px 20px;
	line-height: 1.2;

	@include mq(md) {
		padding: 8px 26px;
		font-size: 18px;
	}

	@include mq(xl) {
		font-size: 22px;
	}

	&.btn-sm {
		font-size: 17px;
		padding: 6px 10px;

		@include mq(md) {
			padding: 8px 12px;
		}
	}

	&.btn-xs {
		font-size: 16px;
		padding: 6px 10px;

		@include mq(md) {
			padding: 8px 24px;
			font-size: 18px !important;
		}
	}

	&.btn-primary {
		color: #fff;

		&:not(:disabled):not(.disabled) {
			&:hover,
			&:active {
				color: #fff;
			}
		}
	}

	&.btn-outline-primary {

		&:not(:disabled):not(.disabled) {
			&:hover,
			&:active {
				color: #fff;
			}
		}
	}
}
