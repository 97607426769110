.sn_block_grid_image_page {

	.sn_grid_items{
		background: #fff;
	}

	.sn_item {
		&_ar > .image {
			.title {
				font-size: 30px;
				color: $white;
				position: absolute;
				bottom: 10px;
				left: 20px;
				z-index: 1;

				@include mq(md){
					font-size: 25px;
				}
			}

			.sn_sprite {
				width: 24px;
				height: 24px;
				content: '';
				position: absolute;
		    bottom: 25px;
				right: 20px;
				margin-left: -12px;
				background-size: contain;
				background-position: center center;
				background-repeat: no-repeat;
				fill: #fff;
				transition: $transition-base;
				z-index: 1;
			}

			&:hover {
				.sn_sprite {
					fill: $primary;
				}
			}

			&::after{
				background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, black 90%);
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				height: 35%;
			}
		}
	}

	.swiper-container {
		.swiper-wrapper {
			align-items: stretch;

			.swiper-slide {
				height: auto;
			}
		}

		.swiper-pagination {
			position: relative;
			bottom: 0;
			margin-top: 40px;
			margin-bottom: 8px;

			&.swiper-pagination-bullets {
				.swiper-pagination-bullet {
					border: 2px solid $secondary;
				}
			}
		}

		.swiper-button-prev,
		.swiper-button-next {
			@include size(30px, 30px);
			margin-top: 0;
			top: auto;
			bottom: 0;
			fill: $gray-900;
		}
	}
}
