.sn_footer {
	padding-top: 40px;
	padding-bottom: 50px;
	font-size: 18px;


	@include mq(md) {
		padding-bottom: 0;
	}

	.h3,
	.h4,
	.h5 {
		color: $gray-700;
		margin-bottom: 15px;
		font-family: $franklin;
		@include font-size(16px, 20px, md);
	}

	&_info {
		@include font-size(13px, 16px, md);
		text-align: center;
		padding-top: 30px;
		padding-bottom: 30px;

		a {
			font-weight: fw(bold);
			color: $body-color;

			&:hover {
				color: $primary;
			}
		}
	}

	&_col {
		margin-top: 20px;

		@include mq(md) {
			margin-top: 0;
		}

		&:first-child {
			margin-top: 0;
		}

		&_address {
			padding-left: 40px;
			position: relative;

			&,
			p {
				@include font-size(16px, 18px, lg);
				line-height: 1.35;
				margin: 0;
			}

			.sn_sprite {
				@include size(25px);
				fill: $body-color;
				position: absolute;
				top: 3px;
				left: 0;
			}
		}

		&_contacts {
			list-style: none;
			margin: 0;
			padding: 0;
			@include font-size(16px, 18px, lg);

			li {
				margin-bottom: 5px;

				a {
					color: $body-color;
					display: inline-flex;
					align-items: center;

					&:hover {
						color: $primary;
					}

					.sn_sprite {
						fill: currentColor;
						@include size(20px);
						margin-right: 5px;
					}
				}
			}
		}

		&_socials {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -5px;

			a {
				display: block;
				@include size(25px);
				margin: 0 5px 10px;
				fill: $body-color;

				&:hover {
					fill: $primary;
				}
			}
		}
	}

	&_credits {
		@include font-size(13px, 16px, md);
		text-align: center;
		border-top: 1px solid #bba888;
		padding-top: 20px;
		padding-bottom: 20px;
		font-weight: fw(regular);

		a {
			font-weight: fw(bold);
			color: $body-color;

			&:hover {
				color: $primary;
			}
		}
	}
}
