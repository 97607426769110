h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	// font-style: italic;
}

h1, .h1 {
	font-size: 33px;

	@include mq(md) {
		font-size: 40px;
	}

	@include mq(xl) {
		font-size: 50px;
	}
}

h2, .h2 {
	font-size: 30px;

	@include mq(md) {
		font-size: 38px;
	}

	@include mq(xl) {
		font-size: 45px;
	}
}

h3, .h3 {
	font-size: 25px;

	@include mq(md) {
		font-size: 30px;
	}

	@include mq(xl) {
		font-size: 35px;
	}
}

h4, .h4 {
	font-size: 20px;

	@include mq(md) {
		font-size: 22px;
	}

	@include mq(xl) {
		font-size: 25px;
	}
}

h5, .h5 {
	font-size: 16px;

	@include mq(md) {
		font-size: 20px;
	}

	@include mq(xl) {
		font-size: 22px;
	}
}

h6, .h6 {
	font-size: 15px;
	font-weight: fw(bold);

	@include mq(md) {
		font-size: 19px;
	}

	@include mq(xl) {
		font-size: 20px;
	}
}


p, ul, ol {
	font-size: 16px;

	@include mq(md) {
		font-size: 20px;
	}

	@include mq(xl) {
		font-size: 22px;
	}
}

hr {
	border-color: #bba888;
	margin: 40px 0;
}

strong {
	font-weight: fw(bold);
}

blockquote {
	padding-left: 95px;
	margin: 50px 0;
	position: relative;
	font-size: 20px;
	line-height: 1.28;
	font-weight: fw(regular);
	font-style: italic;

	@include mq(md) {
		font-size: 22px;
	}

	@include mq(xl) {
		font-size: 25px;
	}

	&::before {
		@include size(37px);
		content: '';
		position: absolute;
		top: 25px;
		left: 0;
		background-size: contain;
		background-position: center top;
		background-repeat: no-repeat;
		background-image: url('../images/svg/quote-open.svg');
	}

	&::after {
		@include size(2px, 100%);
		content: '';
		position: absolute;
		top: 0;
		left: 67px;
		background-color: $primary;
	}

	cite,
	sub {
		display: block;
		font-style: normal;
		font-size: 16px;
		color: $primary;
		line-height: 1;
		margin-top: 10px;
		font-weight: fw(light);

		@include mq(md) {
			font-size: 18px;
		}

		&::before {
			content: '- ';
		}

		&::after {
			content: ' -';
		}
	}

	a {
		font-style: normal;
		font-size: 16px;
		margin-top: 10px;
		font-weight: fw(light);

		@include mq(md) {
			font-size: 18px;
		}
	}
}

.display-1 {
	font-size: 40px;

	@include mq(md) {
		font-size: 75px;
	}

	@include mq(xl) {
		font-size: 110px;
	}
}

.sn_text {
	img {
		display: block;
		margin: 30px auto;
		max-width: 100%;
	}
}
