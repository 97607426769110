.sn_block_image_text {

	background-color: $secondary;
	padding: 40px 0;
	color: #fff;

	&._image_left {
		.sn_block_image_text_col {
			&:first-child {
				> div {
					transform: translateX(100px);
				}
			}

			&:last-child {
				> div {
					transform: translateX(-100px);
				}
			}
		}
	}

	&_col {
		&:first-child {
			> div {
				transform: translateX(-100px);
			}
		}

		&:last-child {
			> div {
				transform: translateX(100px);
			}
		}

		> div {
			opacity: 0;
			transition: $transition-smooth;
		}

		&._in {
			> div {
				transform: translateX(0%) !important;
				opacity: 1;
			}
		}

		.h1 {
			color: #fff;
		}
	}

}
