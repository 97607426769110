.sn_rooms_slider_list {
	$srsl: &;
	background: $secondary;
	padding: 70px 0 90px;

	&._alternate {
		#{$srsl}_i {
			&:nth-child(odd) {
				#{$srsl}_slider { order: 1; }
				#{$srsl}_text {
					order: 2;
					transform: translateX(-100%);
				}
			}
		}
	}

	&_i {
		display: flex;
		align-items: stretch;
		margin-bottom: 65px;
		flex-wrap: wrap;

		@include mq(md){
			flex-wrap: nowrap;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&_slider {
		width: 100%;
		position: relative;
		z-index: 1;
		order: 2;

		@include mq(md){
			width: percentage(2/3);
		}

		.swiper-container {
			height: 100%;

			.swiper-wrapper {
				.swiper-slide {
					height: 260px;
					background-size: cover;
					background-position: center center;
					background-repeat: no-repeat;

					@include mq(md){
						min-height: 540px;
						height: 100%;
					}
				}
			}
		}
	}

	&_text {
		width: 100%;
		background: #fff;
		padding: 30px 25px;
		transition: $transition-smooth;
		transform: translateX(100%);
		order: 1;

		@include mq(md){
			width: percentage(1/3);
			padding: 30px;
		}

		@include mq(xl){
			padding: 50px 30px;
		}

		span {
			font-size: 18px;
		}

		._in & {
			transform: translateX(0%) !important;
		}

		.title {
			@extend .h3;
			font-size: 25px;
			margin-bottom: 10px;
			font-family: $franklin;
			color: $body-color;

			@include mq(xl){
				font-size: 40px;
			}
		}

		p {
			font-size: 18px;

			@include mq(xl){
				font-size: 22px;
			}
		}

		.btns {
			margin-top: 30px;

			@include mq(xl){
				margin-top: 65px;
			}

			> div {
				margin-bottom: 10px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			.btn {
				line-height: 1.1;
				padding: 12px 8px;
				min-width: auto;
				max-width: auto;
				width: 100%;
				display: block;

				@include mq(md){
					padding: 15px 12px;
					min-width: auto;
					width: 100%;
					display: block;
				}

				@include mq(lg){
					min-width: 240px;
					width: auto;
					display: inline-block;
				}
			}
		}
	}
}
